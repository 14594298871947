import { useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { Form, Input, Button, Typography, Alert } from 'antd';
import { AuthService, ChangePassword, ChangePasswordWithCode, Login } from '../../services/auth';
import { User, UserManager, UserSettings } from 'oidc-client';
import { AnalyticsOidcConfiguration } from '../../oidc-configuration';
import Routes from '../../page-routes';
import { LoginErrorMessage, SubmitMFA } from '../../services/auth/auth-service';
import { isBoolean } from 'util';

const { Title } = Typography;

const inputWidth = 300;

interface FormFields {
  email: string,
  code: string
}

interface Location extends Login {
  resetFlow: boolean;
  session: string;
  challenge: string;
}

type FormState = 'input' | 'loading' | 'error'

export const MFAChallengeForm = () => {
  const [form] = Form.useForm()
  const location = useLocation<Location>()
  const history = useHistory();

  const initialFormState: FormState = 'input'
  const [formState, setFormState] = useState<FormState>(initialFormState)
  const [errorMsg, setErrorMsg] = useState<undefined | string>(undefined)
  const userEmail: string = location.state?.email;
  const currentSession: string = location.state?.session;
  const mfaType: string = location.state?.challenge

  const onFinish = async (form: FormFields) => {
    setErrorMsg(undefined)
    setFormState('loading')
    const response =  await AuthService.submitMFA({
        email: userEmail,
        code: form.code,
        session: currentSession,
        type: mfaType
      } as SubmitMFA);

      console.log("mfa response: " +JSON.stringify(response))

    if (response instanceof Error) {
      setErrorMsg(response.message);
      setFormState('error')
    }
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    else if (response.access_token) {
      handleSuccessUserLogin(response as UserSettings);

    }
    else {
      setErrorMsg(LoginErrorMessage.UnknownError);
      setFormState('error')
    }
  };

  const handleSuccessUserLogin = async (userSettings: UserSettings) => {
    const m = new UserManager(AnalyticsOidcConfiguration);

    await m.storeUser(new User(userSettings)).then(() => {
      window.location.href = Routes.Analytics;
    });
  };

  return  !userEmail ? <>{history.push('/login')}</> : (
    <div style={{
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'flex-start'
    }}>
      <div className="mfa-code-form" style={{
        background: 'white',
        margin: '100px auto 20px',
        boxShadow: "0px 4px 8px 0px rgb(221 221 221)",
        borderRadius: "4px",
        padding: '42px 42px 20px'
      }}>
        <div className="mfa-code-title"
          style={{ marginBottom: '24px' }}>
          <Title level={4} style={{ paddingBottom: '16px' }}>Multifactor Authentication</Title>
        </div>
        <div className="mfa-password-form-inputs">
          <Form
            form={form}
            layout="vertical"
            onFinish={onFinish}
            requiredMark={false}
          >
            <Form.Item
              name="email"
              label="Email" style={{ width: inputWidth }}>
              <span className="ant-form-text">{userEmail}</span>
            </Form.Item>
            <Form.Item
              name="code"
              label={"MFA Code"}
              style={{ width: inputWidth }}
              rules={[{ required: true, message: `Please input you mfa code` }]}>
              <Input.Password placeholder="" />
            </Form.Item>
            <Form.Item>
              <div style={{display: 'flex', justifyContent: 'space-between'}}>
                <a href="/login">
                <Button
                >
                  Cancel
                </Button>
              </a>
                <Button type="primary" htmlType="submit" loading={formState === 'loading'}>Confirm</Button>
              </div>
            </Form.Item>
          </Form>
        </div>
        {
          formState === 'error' &&
          <div className="mfa-form-error">
            <Alert message={errorMsg} type="error" showIcon />
          </div>
        }
        {errorMsg && <p className="text-danger">{errorMsg}</p>}
      </div>
    </div>
  );
};
