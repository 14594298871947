import AWS from "aws-sdk";
import { User, UserManager, UserSettings } from "oidc-client";
import { decode } from "jsonwebtoken";
import { AnalyticsOidcConfiguration } from "./../../oidc-configuration";
import { Login } from "../../routes";

const getUserManager = () => analyticsManager;

const CLIENT_ID = process.env.REACT_APP_OAUTH_ANALYTICS_CLIENT_ID as string;

AWS.config.update({
  region: process.env.REACT_APP_AWS_REGION,
});

const analyticsManager = new UserManager(AnalyticsOidcConfiguration);
export type AuthContext = "analytics";

export type AuthChallengeType = "SMS_MFA" | "EMAIL_OTP" | "SOFTWARE_TOKEN_MFA" | "SELECT_MFA_TYPE" | "MFA_SETUP" | "PASSWORD_VERIFIER" | "CUSTOM_CHALLENGE" | "DEVICE_SRP_AUTH" | "DEVICE_PASSWORD_VERIFIER" | "ADMIN_NO_SRP_AUTH" | "NEW_PASSWORD_REQUIRED"

export enum LoginErrorMessage {
  InvalidCombination = "Invalid Email/Password combination",
  NoAccessToken = "No access token was returned in the result",
  UnknownError = "An unknown error has occurred",
  NewPasswordRequired = "New Password Required",
  ResetError = "Please ensure email is correct and account confirmed"
}

export interface Login {
  email: string;
  password: string;
}

export interface AuthChallenge {
  challenge: AuthChallengeType;
  session: string;
}

export interface ChangePassword {
  email: string;
  oldPassword: string;
  newPassword: string;
}

export interface SubmitMFA {
  email: string;
  code: string;
  session: string;
  type: string;
}

export interface ChangePasswordWithCode {
  email: string;
  code: string;
  newPassword: string;
}

export const CognitoIdentity = "Cognito";

export interface ILoginIdentityResponse {
  identity: string;
}

interface AuthService {
  changePassword: (
    changePassword: ChangePassword
    ) => Promise<UserSettings | Error | AuthChallenge>;
  submitMFA: (
    submitMFA: SubmitMFA
    ) => Promise<UserSettings | Error>;
  forgotPassword: (username: string) => Promise<'success' | Error>;
  getUser: () => Promise<User | null>;
  getUserManager: () => UserManager;
  login: (
    login: Login
  ) => Promise<ILoginIdentityResponse | UserSettings | Error | AuthChallenge>;
  setNewPasswordWithCode: (
    changePassword: ChangePasswordWithCode
  ) => Promise<ILoginIdentityResponse | UserSettings | Error | AuthChallenge>;
}

export const AuthService: AuthService = {
  getUser: () => getUserManager().getUser(),
  getUserManager: () => getUserManager(),
  login: async (login: Login) => {
    if (login.password) {
      return passwordLogin(login);
    }

    const { email } = login;

    const response = await fetch(
      `${process.env.REACT_APP_API_ROOT}/login/identity`,
      { method: "POST", body: JSON.stringify({ email }) }
    )
      .then((x) => x.json())
      .then((x) => x as ILoginIdentityResponse);

    return response;
  },

  changePassword: async ({
    email,
    oldPassword,
    newPassword,
  }: ChangePassword): Promise<UserSettings | Error | AuthChallenge> => {
    const cog = new AWS.CognitoIdentityServiceProvider();
    const user = await cog
      .initiateAuth({
        AuthFlow: "USER_PASSWORD_AUTH",
        ClientId: CLIENT_ID,
        AuthParameters: {
          USERNAME: email,
          PASSWORD: oldPassword,
        },
      })
      .promise()
      .then(async ({ AuthenticationResult, ChallengeName, Session }) => {
        if (ChallengeName == "NEW_PASSWORD_REQUIRED") {
          const newUser = await cog.respondToAuthChallenge(
            {
              ChallengeName: "NEW_PASSWORD_REQUIRED",
              ChallengeResponses: {
                USERNAME: email,
                NEW_PASSWORD: newPassword
              },
              ClientId: CLIENT_ID,
              Session
            },
          ).promise()

          if (["SMS_MFA", "EMAIL_OTP"].includes(newUser.ChallengeName as AuthChallengeType)) {
            return {
              challenge: newUser.ChallengeName as AuthChallengeType,
              session: newUser.Session

            } as AuthChallenge
          }


          console.log(JSON.stringify(newUser));

          if (!newUser.AuthenticationResult) {
            return Error(LoginErrorMessage.UnknownError);
          }
          if (!newUser.AuthenticationResult.AccessToken) {
            return Error(LoginErrorMessage.NoAccessToken);
          }

          const profile = decode(newUser.AuthenticationResult.AccessToken);

          return {
            access_token: newUser.AuthenticationResult.AccessToken,
            expires_at: Date.now() + (newUser.AuthenticationResult.ExpiresIn || 0),
            id_token: newUser.AuthenticationResult.IdToken,
            refresh_token: newUser.AuthenticationResult.RefreshToken,
            token_type: newUser.AuthenticationResult.TokenType,
            profile,
          } as UserSettings;
        }
        if (["SMS_MFA", "EMAIL_OTP"].includes(ChallengeName as AuthChallengeType)) {
          return {
            challenge: ChallengeName as AuthChallengeType,
            session: Session

          } as AuthChallenge
        }

        if (!AuthenticationResult) {
          return Error(LoginErrorMessage.UnknownError);
        }
      })
      .catch((any) => {
        console.log("Error occurred while resetting password " + any)
        return Error(LoginErrorMessage.InvalidCombination);
      });
    if (!user) {
      return Error(LoginErrorMessage.UnknownError);
    }
    return user;
  },
  submitMFA: async ({
    email,
    code,
    session,
    type,
  }: SubmitMFA): Promise<UserSettings | Error> => {
    const cog = new AWS.CognitoIdentityServiceProvider();
    const user = await cog.respondToAuthChallenge(
            {
              ChallengeName: type,
              ChallengeResponses: {
                USERNAME: email,
                [`${type}_CODE`]: code
              },
              ClientId: CLIENT_ID,
              Session: session
            },
          ).promise()
          .then((user) => {
                      if (!user.AuthenticationResult) {
            return Error(LoginErrorMessage.UnknownError);
          }
          if (!user.AuthenticationResult.AccessToken) {
            return Error(LoginErrorMessage.NoAccessToken);
          }

          const profile = decode(user.AuthenticationResult.AccessToken);

          return {
            access_token: user.AuthenticationResult.AccessToken,
            expires_at: Date.now() + (user.AuthenticationResult.ExpiresIn || 0),
            id_token: user.AuthenticationResult.IdToken,
            refresh_token: user.AuthenticationResult.RefreshToken,
            token_type: user.AuthenticationResult.TokenType,
            profile,
          } as UserSettings;
          }) .catch((error) => { 
          console.log("ERROR" + JSON.stringify(error))
      return Error(LoginErrorMessage.InvalidCombination);
    });
    return user
        },
  forgotPassword: async (username: string) => { // Just triggers Cognito to send a code to the users tmail
    const params = {
      ClientId : CLIENT_ID,
      Username : username,
    };

    const cog = new AWS.CognitoIdentityServiceProvider();
    try {
      return await cog.forgotPassword(params)
      .promise().then(() => 'success')
    } catch (error) {
      return Error(LoginErrorMessage.ResetError);
    }
  },
  setNewPasswordWithCode: async ({ // This will only work for users who have signed into the app previously to confirm their account
    email,
    code,
    newPassword,
  }: ChangePasswordWithCode): Promise<any> => {
    const cog = new AWS.CognitoIdentityServiceProvider();
    return await cog
      .confirmForgotPassword({
        ConfirmationCode: code,
        ClientId: CLIENT_ID,
        Username: email,
        Password: newPassword,
      })
    .promise().then(async () => await passwordLogin({email, password: newPassword}))
  }
}


// const getPhoneNumber = async (accessToken): Promise<Boolean | Error> => {
//   const cog = new AWS.CognitoIdentityServiceProvider();
//   try {
//    const params = {
//      AccessToken: accessToken,
//    };
//    const userData =  await cog.getUser().promise(

//    )

//    // Check if phone number is verified
//    const phoneVerified = userData.UserAttributes?.find(
//      (attr) => attr.Name === 'phone_number',
//    );

//    return phoneVerified && phoneVerified.Value === 'true';
//  } catch (err) {
//    console.error('Error checking phone number verification:', err);
//    throw new Error('Unable to check phone number verification');
//  }
// }



// async updatePhoneNumber(
//   accessToken: string,
//   phoneNumber: string,
// ): Promise<void> {
//   const params = {
//     AccessToken: accessToken,
//     UserAttributes: [
//       {
//         Name: 'phone_number',
//         Value: phoneNumber,
//       },
//     ],
//   };

//   const command = new UpdateUserAttributesCommand(params);
//   await this.client.send(command);
// }


const passwordLogin = async ({
  email,
  password,
}: Login): Promise<Error | UserSettings | AuthChallenge> => {
  const cog = new AWS.CognitoIdentityServiceProvider();

  console.log(process.env.REACT_APP_AWS_REGION);

  const user = await cog
    .initiateAuth({
      AuthFlow: "USER_PASSWORD_AUTH",
      ClientId: CLIENT_ID,
      AuthParameters: {
        USERNAME: email,
        PASSWORD: password,
      },
    })
    .promise()
    .then(({ AuthenticationResult, ChallengeName, Session }) => {
      if (ChallengeName) {
        return {
          challenge: ChallengeName as AuthChallengeType,
          session: Session

        } as AuthChallenge

      }
      if (!AuthenticationResult) {
        return Error(LoginErrorMessage.UnknownError);
      }

      if (!AuthenticationResult.AccessToken) {
        return Error(LoginErrorMessage.NoAccessToken);
      } else {
        cog.getUser({
          AccessToken: AuthenticationResult.AccessToken /* required */
        }, function(err, data) {
          if (err){
            console.log("ERROR");
            console.log(err, err.stack); // an error occurred
          } 
          else{
            const phoneVerified = data.UserAttributes?.find(
              (attr) => attr.Name === 'phone_number',
            );
            console.log("ERERERERER" +phoneVerified);
          }          // successful response
        });
      }

      const profile = decode(AuthenticationResult.AccessToken);

      console.log(JSON.stringify(profile))

      console.log(JSON.stringify(AuthenticationResult));

      return {
        access_token: AuthenticationResult.AccessToken,
        expires_at: Date.now() + (AuthenticationResult.ExpiresIn || 0),
        id_token: AuthenticationResult.IdToken,
        refresh_token: AuthenticationResult.RefreshToken,
        token_type: AuthenticationResult.TokenType,
        profile,
      } as UserSettings;
    })
    .catch(() => {
      return Error(LoginErrorMessage.InvalidCombination);
    });

  return user;
};
